"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.UploadedFiles = void 0;
const core_1 = require("@material-ui/core");
const Delete_1 = require("@material-ui/icons/Delete");
const bi_1 = require("react-icons/bi");
const React = require("react");
const backend_utils = require("../../../utils/backend-connect");
const frontend_utils = require("../../../utils/frontend-connect");
const DatasetType_1 = require("../../Utility/Data/DatasetType");
const react_promise_tracker_1 = require("react-promise-tracker");
const LoadingIndicator_1 = require("../../Utility/Loaders/LoadingIndicator");
const promise_helpers_1 = require("../../../utils/promise-helpers");
exports.UploadedFiles = ({ onChange, refresh }) => {
    const [files, setFiles] = React.useState(null);
    const { cancellablePromise } = promise_helpers_1.default();
    React.useEffect(() => {
        update_files();
    }, [refresh]);
    var handleClick = (entry) => {
        onChange(entry);
    };
    const loading_area = "update_uploaded_files_list";
    function update_files() {
        react_promise_tracker_1.trackPromise(cancellablePromise(backend_utils.get_uploaded_files()).then(data => {
            if (data && Object.keys(data).includes("file_list"))
                setFiles(data["file_list"]);
        }).catch(error => console.log(error)), loading_area);
    }
    var handleDelete = (file_name) => {
        cancellablePromise(backend_utils.delete_file(file_name)).then(x => {
            if (x && x['deleted'] == "true") {
                const new_files = [...files];
                const index = new_files.indexOf(file_name);
                if (index > -1) {
                    new_files.splice(index, 1);
                }
                setFiles(new_files);
            }
        }).catch(error => console.log(error));
    };
    return (files && React.createElement("div", null,
        React.createElement(core_1.Grid, { item: true, style: { overflowY: 'auto', flex: '1 1 auto', maxHeight: '400px' } },
            React.createElement(core_1.List, { subheader: React.createElement("li", null), style: { backgroundColor: 'white' } },
                !frontend_utils.DEMO && React.createElement(core_1.ListSubheader, null,
                    "Uploaded Files ",
                    React.createElement(core_1.Button, { onClick: () => update_files() },
                        React.createElement(bi_1.BiRefresh, null))),
                frontend_utils.DEMO && React.createElement(core_1.ListSubheader, null, "Select Dataset"),
                files.map(file_name => (React.createElement(core_1.ListItem, { style: { maxWidth: '270px', }, key: file_name, button: true, onClick: () => {
                        handleClick({
                            display: file_name,
                            path: file_name,
                            type: DatasetType_1.DatasetType.Chem,
                            uploaded: true // indicates that file is already uploaded
                        });
                    } },
                    React.createElement(core_1.ListItemText, { primary: file_name }),
                    !frontend_utils.DEMO && React.createElement(core_1.ListItemSecondaryAction, { onClick: () => { handleDelete(file_name); } },
                        React.createElement(core_1.IconButton, { edge: "end", "aria-label": "delete" },
                            React.createElement(Delete_1.default, null))))))),
            React.createElement(LoadingIndicator_1.LoadingIndicatorView, { area: loading_area })))); //<Divider/>
};
