"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.TrrackLegend = void 0;
var d3 = require('d3');
const React = require("react");
const react_redux_1 = require("react-redux");
const vega_tooltip_1 = require("vega-tooltip");
const TrrackScatter_js_1 = require("./TrrackScatter.js");
function getScatter(vectors, dataset) {
    console.log('Trrack: vectors :>> ', vectors);
    // init empty "data" array
    const data = [];
    // iterate vectors v
    vectors.forEach(v => {
        // parse v to array
        var parsed = JSON.parse((v['selectedCoordsNorm'].replace(/'/g, '\"')));
        // concat parsed v to data
        data.push(...parsed);
    });
    return (React.createElement("div", { style: { width: "100%", maxHeight: '100%' } },
        React.createElement("div", { style: {
                justifyContent: "center",
                display: "flex",
                width: "100%",
                overflow: "auto"
            } },
            React.createElement(TrrackScatter_js_1.default, { data: { 'values': data }, actions: false, tooltip: new vega_tooltip_1.Handler().call }))));
}
const mapState = (state) => {
    return ({
        dataset: state.dataset
    });
};
const mapDispatch = dispatch => ({});
const connector = react_redux_1.connect(mapState, mapDispatch);
exports.TrrackLegend = connector(({ selection, dataset }) => {
    return getScatter(selection, dataset);
});
