"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.restoreNumberFilter = exports.restoreMapping = exports.isDummyNumberFilter = exports.isUnknown = exports.similar = exports.isEqualNumberFilter = exports.noNumberFilter = exports.DEFAULT_FORMATTER = void 0;
const d3_1 = require("d3");
const lineupjs_1 = require("lineupjs");
exports.DEFAULT_FORMATTER = d3_1.format('.3n');
function noNumberFilter() {
    return ({ min: -Infinity, max: Infinity, filterMissing: false });
}
exports.noNumberFilter = noNumberFilter;
function isEqualNumberFilter(a, b, delta = 0.001) {
    return similar(a.min, b.min, delta) && similar(a.max, b.max, delta) && a.filterMissing === b.filterMissing;
}
exports.isEqualNumberFilter = isEqualNumberFilter;
function similar(a, b, delta = 0.5) {
    if (a === b) {
        return true;
    }
    return Math.abs(a - b) < delta;
}
exports.similar = similar;
function isUnknown(v) {
    return v == null || v === undefined || isNaN(v);
}
exports.isUnknown = isUnknown;
function isDummyNumberFilter(filter) {
    return !filter.filterMissing && !isFinite(filter.min) && !isFinite(filter.max);
}
exports.isDummyNumberFilter = isDummyNumberFilter;
function restoreMapping(desc, factory) {
    if (desc.map) {
        return factory.mappingFunction(desc.map);
    }
    return new lineupjs_1.ScaleMappingFunction(desc.domain || [0, 1], 'linear', desc.range || [0, 1]);
}
exports.restoreMapping = restoreMapping;
function restoreNumberFilter(v) {
    return {
        min: v.min != null && isFinite(v.min) ? v.min : -Infinity,
        max: v.max != null && isFinite(v.max) ? v.max : +Infinity,
        filterMissing: v.filterMissing
    };
}
exports.restoreNumberFilter = restoreNumberFilter;
