"use strict";
// CONSTANTS
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.calculate_hdbscan_clusters = exports.get_representation_list = exports.upload_sdf_file = exports.get_substructure_count = exports.get_mcs_from_smiles_list = exports.get_structures_from_smiles_list = exports.get_structure_from_smiles = exports.get_difference_highlight = exports.get_uploaded_files = exports.delete_file = exports.BASE_URL = exports.CREDENTIALS = void 0;
exports.CREDENTIALS = 'include'; // for AWS/docker
// export const CREDENTIALS = 'omit'; // for netlify/local
// export const BASE_URL = 'https://cime-demo.caleydoapp.org'; // for netlify
// export const BASE_URL = 'http://127.0.0.1:8080'; // for local
exports.BASE_URL = ''; // for AWS/docker
var smiles_cache = {};
var smiles_highlight_cache = {};
function handleSmilesCache(smiles, highlight = false) {
    //already downloaded this image -> saved in smiles cache
    if (highlight) {
        return smiles_highlight_cache[smiles];
    }
    else {
        return smiles_cache[smiles];
    }
}
function setSmilesCache(smiles, highlight = false, data) {
    if (highlight)
        smiles_highlight_cache[smiles] = data;
    else
        smiles_cache[smiles] = data;
}
function async_cache(cached_data) {
    return __awaiter(this, void 0, void 0, function* () {
        return cached_data;
    });
}
var cache = {};
function handleCache(key) {
    if (cache[key])
        return Object.assign(cache[key]); // return copy of cached object
    return null;
}
function setCache(key, value) {
    cache[key] = value;
}
function handle_errors(response) {
    if (!response.ok) {
        throw Error(response.statusText);
    }
    return response;
}
function handle_errors_json(data) {
    if (Object.keys(data).includes("error")) {
        alert(data["error"]);
    }
    return data;
}
function delete_file(filename) {
    return __awaiter(this, void 0, void 0, function* () {
        let path = exports.BASE_URL + '/delete_file/' + filename;
        return fetch(path, {
            method: 'GET',
            credentials: exports.CREDENTIALS
        })
            .then(handle_errors)
            .then(response => response.json())
            .then(handle_errors_json)
            .catch(error => {
            alert("file could not be deleted. please, try again");
            console.log(error);
        });
    });
}
exports.delete_file = delete_file;
function get_uploaded_files() {
    return __awaiter(this, void 0, void 0, function* () {
        let path = exports.BASE_URL + '/get_uploaded_files_list';
        return fetch(path, {
            method: 'GET',
            credentials: exports.CREDENTIALS
        })
            .then(handle_errors)
            .then(response => response.json())
            .then(handle_errors_json)
            .catch(error => {
            // alert("could not load uploaded filenames.")
            console.log(error);
        });
    });
}
exports.get_uploaded_files = get_uploaded_files;
function get_difference_highlight(smilesA, smilesB, controller = null) {
    return __awaiter(this, void 0, void 0, function* () {
        const formData = new FormData();
        formData.append('smilesA', smilesA);
        formData.append('smilesB', smilesB);
        let path = exports.BASE_URL + '/get_difference_highlight';
        let my_fetch = null;
        if (controller) {
            my_fetch = fetch(path, {
                method: 'POST',
                body: formData,
                credentials: exports.CREDENTIALS,
                signal: controller.signal
            });
        }
        else {
            my_fetch = fetch(path, {
                method: 'POST',
                body: formData,
                credentials: exports.CREDENTIALS,
            });
        }
        return my_fetch
            .then(handle_errors)
            .then(response => response.json())
            .then(handle_errors_json)
            .then(data => {
            console.log(data);
            return data["data"];
        })
            .catch(error => {
            // alert("could not load structure");
            console.log(error);
        });
    });
}
exports.get_difference_highlight = get_difference_highlight;
function get_structure_from_smiles(smiles, highlight = false, controller = null) {
    return __awaiter(this, void 0, void 0, function* () {
        const cached_data = handleSmilesCache(smiles, highlight);
        if (cached_data) {
            return async_cache(cached_data);
        }
        const formData = new FormData();
        formData.append('smiles', smiles);
        if (localStorage.getItem("unique_filename"))
            formData.append('filename', localStorage.getItem("unique_filename"));
        let path = exports.BASE_URL + '/get_mol_img';
        if (highlight) {
            path += "/highlight";
        }
        let my_fetch = null;
        if (controller) {
            my_fetch = fetch(path, {
                method: 'POST',
                body: formData,
                credentials: exports.CREDENTIALS,
                signal: controller.signal
            });
        }
        else {
            my_fetch = fetch(path, {
                method: 'POST',
                body: formData,
                credentials: exports.CREDENTIALS,
            });
        }
        return my_fetch
            .then(handle_errors)
            .then(response => response.json())
            .then(handle_errors_json)
            .then(data => {
            setSmilesCache(smiles, highlight, data["data"]);
            return data["data"];
        })
            .catch(error => {
            // alert("could not load structure");
            console.log(error);
        });
    });
}
exports.get_structure_from_smiles = get_structure_from_smiles;
function get_structures_from_smiles_list(formData, controller) {
    return __awaiter(this, void 0, void 0, function* () {
        if (localStorage.getItem("unique_filename"))
            formData.append('filename', localStorage.getItem("unique_filename"));
        let my_fetch = null;
        if (controller) {
            my_fetch = fetch(exports.BASE_URL + '/get_mol_imgs', {
                method: 'POST',
                body: formData,
                credentials: exports.CREDENTIALS,
                signal: controller === null || controller === void 0 ? void 0 : controller.signal
            });
        }
        else {
            my_fetch = fetch(exports.BASE_URL + '/get_mol_imgs', {
                method: 'POST',
                body: formData,
                credentials: exports.CREDENTIALS,
            });
        }
        return my_fetch
            .then(handle_errors)
            .then(response => response.json())
            .then(handle_errors_json)
            .then(data => {
            if (data["error_smiles"].length > 0) {
                alert("following smiles couldn not be parsed: " + data["error_smiles"]);
            }
            return data;
        })
            .catch(error => {
            if (error.name === 'AbortError') {
                console.log('Fetch aborted');
            }
            else {
                alert("could not load structures");
                console.log(error);
            }
        });
    });
}
exports.get_structures_from_smiles_list = get_structures_from_smiles_list;
function get_mcs_from_smiles_list(formData, controller) {
    return __awaiter(this, void 0, void 0, function* () {
        let my_fetch = null;
        if (controller) {
            my_fetch = fetch(exports.BASE_URL + '/get_common_mol_img', {
                method: 'POST',
                body: formData,
                signal: controller === null || controller === void 0 ? void 0 : controller.signal
            });
        }
        else {
            my_fetch = fetch(exports.BASE_URL + '/get_common_mol_img', {
                method: 'POST',
                body: formData,
            });
        }
        return my_fetch
            .then(handle_errors)
            .then(response => response.json())
            .then(handle_errors_json)
            .then(response => response["data"])
            .catch(error => {
            // alert("could not get maximum common substructure")
            console.log(error);
        });
    });
}
exports.get_mcs_from_smiles_list = get_mcs_from_smiles_list;
function get_substructure_count(smiles_list, filter) {
    return __awaiter(this, void 0, void 0, function* () {
        const formData = new FormData();
        formData.append('smiles_list', smiles_list);
        formData.append('filter_smiles', filter);
        return fetch(exports.BASE_URL + '/get_substructure_count', {
            method: 'POST',
            body: formData,
        })
            .then(handle_errors)
            .then(response => response.json())
            .then(handle_errors_json)
            .then(data => {
            if (Object.keys(data).includes("substructure_counts"))
                return data["substructure_counts"];
            else
                throw Error("Backend responded with error: " + data["error"]);
        })
            .catch(error => {
            alert("could not find substructure match");
            console.log(error);
        });
    });
}
exports.get_substructure_count = get_substructure_count;
function upload_sdf_file(file, controller) {
    return __awaiter(this, void 0, void 0, function* () {
        // upload the sdf file to the server
        // the response is a unique filename that can be used to make further requests
        const formData_file = new FormData();
        formData_file.append('myFile', file);
        formData_file.append('file_size', file.size);
        const promise = fetch(exports.BASE_URL + '/upload_sdf', {
            method: 'POST',
            body: formData_file,
            credentials: exports.CREDENTIALS,
            signal: controller === null || controller === void 0 ? void 0 : controller.signal
        })
            .then(handle_errors)
            .then(response => response.json())
            .then(handle_errors_json)
            .then(data => {
            localStorage.setItem("unique_filename", data["unique_filename"]);
        })
            .catch(error => {
            if (error.name === 'AbortError') {
                console.log('Fetch aborted');
            }
            else {
                alert("error when uploading file. it might be too big");
                console.log(error);
            }
        });
        return promise;
    });
}
exports.upload_sdf_file = upload_sdf_file;
function get_representation_list(refresh = false, dataset_name = "", controller = null) {
    return __awaiter(this, void 0, void 0, function* () {
        if (!refresh) {
            const cached_data = handleCache("representation_list_" + dataset_name);
            if (cached_data && cached_data["rep_list"].length > 0) {
                return async_cache(cached_data);
            }
        }
        let path = exports.BASE_URL + '/get_atom_rep_list';
        if (localStorage.getItem("unique_filename"))
            path += "/" + localStorage.getItem("unique_filename");
        let my_fetch = null;
        if (controller) {
            my_fetch = fetch(path, {
                method: 'GET',
                credentials: exports.CREDENTIALS,
                signal: controller.signal
            });
        }
        else {
            my_fetch = fetch(path, {
                method: 'GET',
                credentials: exports.CREDENTIALS
            });
        }
        return my_fetch
            .then(handle_errors)
            .then(response => response.json())
            .then(handle_errors_json)
            .then(data => {
            setCache("representation_list_" + dataset_name, data);
            return data;
        })
            .catch(error => {
            // alert("error when loading representation list")
            console.log(error);
        });
    });
}
exports.get_representation_list = get_representation_list;
function calculate_hdbscan_clusters(X, min_cluster_size, min_cluster_samples, allow_single_cluster) {
    return __awaiter(this, void 0, void 0, function* () {
        const formData = new FormData();
        formData.append('min_cluster_size', min_cluster_size);
        formData.append('min_cluster_samples', min_cluster_samples);
        formData.append('allow_single_cluster', allow_single_cluster);
        formData.append('X', X);
        return fetch(exports.BASE_URL + '/segmentation', {
            method: 'POST',
            body: formData
        })
            .then(handle_errors)
            .then(response => response.json())
            .then(handle_errors_json)
            .catch(error => {
            alert("error when calculating clusters");
            console.log(error);
        });
    });
}
exports.calculate_hdbscan_clusters = calculate_hdbscan_clusters;
