"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.InferCategory = void 0;
const ChessFingerprint_1 = require("../../legends/ChessFingerprint/ChessFingerprint");
const RubikFingerprint_1 = require("../../legends/RubikFingerprint/RubikFingerprint");
const DatasetType_1 = require("./DatasetType");
/**
 * Object responsible for infering things from the data structure of a csv file.
 * For example this class can infer the
 * - ranges of columns
 * - type of data file (rubik, story...)
 */
class InferCategory {
    constructor(vectors) {
        this.vectors = vectors;
    }
    /**
     * Infers the type of the dataset from the columns
     * @param {*} header
     */
    inferType() {
        var header = Object.keys(this.vectors[0]);
        // Checks if the header has all the required columns
        const hasLayout = (header, columns) => {
            for (let key in columns) {
                let val = columns[key];
                if (!header.includes(val)) {
                    return false;
                }
            }
            return true;
        };
        if (hasLayout(header, RubikFingerprint_1.requiredRubikColumns)) {
            return DatasetType_1.DatasetType.Rubik;
        }
        if (header.includes('cf00')) {
            return DatasetType_1.DatasetType.Neural;
        }
        if (hasLayout(header, ChessFingerprint_1.requiredChessColumns)) {
            return DatasetType_1.DatasetType.Chess;
        }
        if (header.includes('new_y')) {
            return DatasetType_1.DatasetType.Story;
        }
        if (header.includes('aa')) {
            return DatasetType_1.DatasetType.Go;
        }
        // if (header.toString().toLowerCase().includes('smiles')) {
        //     return DatasetType.Chem;
        // }
        if (header.includes('selectedCoordsNorm')) {
            return DatasetType_1.DatasetType.Trrack;
        }
        return DatasetType_1.DatasetType.None;
    }
}
exports.InferCategory = InferCategory;
