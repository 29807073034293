"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.FeatureType = void 0;
/**
 * The data type of a feature
 */
var FeatureType;
(function (FeatureType) {
    FeatureType[FeatureType["String"] = 0] = "String";
    FeatureType[FeatureType["Quantitative"] = 1] = "Quantitative";
    FeatureType[FeatureType["Categorical"] = 2] = "Categorical";
    FeatureType[FeatureType["Date"] = 3] = "Date";
    FeatureType[FeatureType["Binary"] = 4] = "Binary";
    FeatureType[FeatureType["Ordinal"] = 5] = "Ordinal";
})(FeatureType = exports.FeatureType || (exports.FeatureType = {}));
