"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.TSNEEmbeddingController = void 0;
const EmbeddingController_1 = require("./EmbeddingController");
const frontend_utils = require("../../../../utils/frontend-connect");
class TSNEEmbeddingController extends EmbeddingController_1.EmbeddingController {
    init(dataset, selection, params) {
        this.worker = new Worker(frontend_utils.BASE_PATH + 'tsne.js'); //dist/
        var tensor = dataset.asTensor(selection.filter(e => e.checked), null, params.encodingMethod, params.normalizationMethod); // for gower, we don't need one-hot-encoding
        this.worker.postMessage({
            messageType: 'init',
            input: tensor.tensor,
            seed: dataset.vectors.map(sample => [sample.x, sample.y]),
            params: params,
            featureTypes: tensor.featureTypes
        });
        this.worker.addEventListener('message', (e) => {
            var Y = e.data;
            this.stepper(Y);
            this.notifier();
        }, false);
    }
    step() {
        this.worker.postMessage({
            messageType: 'step'
        });
    }
}
exports.TSNEEmbeddingController = TSNEEmbeddingController;
