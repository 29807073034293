"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.TestColumn = void 0;
const d3_1 = require("d3");
const lineupjs_1 = require("lineupjs");
const helper_methods_1 = require("./helper_methods");
let TestColumn = /** @class */ (() => {
    var TestColumn_1;
    let TestColumn = TestColumn_1 = class TestColumn extends lineupjs_1.MapColumn {
        constructor(id, desc, factory) {
            super(id, desc);
            this.numberFormat = helper_methods_1.DEFAULT_FORMATTER;
            /**
             * currently active filter
             * @type {{min: number, max: number}}
             * @private
             */
            this.currentFilter = helper_methods_1.noNumberFilter();
            this.min = 0;
            this.max = 1;
            // this.mapping = restoreMapping(desc, factory); // TODO: check, if desc.range and desc.domain can be infered
            this.mapping = new lineupjs_1.ScaleMappingFunction([desc["min"], desc["max"]], 'linear', [0, 1]);
            this.original = this.mapping.clone();
            this.sort = desc.sort || lineupjs_1.EAdvancedSortMethod.median;
            this.colorMapping = factory.colorMappingFunction(desc.colorMapping || desc.color);
            if (desc.numberFormat) {
                this.numberFormat = d3_1.format(desc.numberFormat);
            }
            //TODO: infer min and max if it is not given
            this.min = desc["min"];
            this.max = desc["max"];
        }
        getMin() {
            return this.min;
        }
        getMax() {
            return this.max;
        }
        getNumberFormat() {
            return this.numberFormat;
        }
        // https://stackoverflow.com/questions/45309447/calculating-median-javascript
        get_quartile(values, q = 0.5) {
            if (values.length === 0)
                return 0;
            values.sort(function (a, b) {
                return a - b;
            });
            var half = Math.floor(values.length * q);
            if (values.length % 2)
                return values[half];
            return (values[half - 1] + values[half]) / 2.0;
        }
        // https://www.sitepoint.com/community/t/calculating-the-average-mean/7302/2
        mean(numbers) {
            var total = 0, i;
            for (i = 0; i < numbers.length; i += 1) {
                total += numbers[i];
            }
            return total / numbers.length;
        }
        get_advanced_value(method, value_list) {
            switch (method) {
                case lineupjs_1.EAdvancedSortMethod.min:
                    return Math.min(...value_list);
                case lineupjs_1.EAdvancedSortMethod.max:
                    return Math.max(...value_list);
                case lineupjs_1.EAdvancedSortMethod.mean:
                    return this.mean(value_list);
                case lineupjs_1.EAdvancedSortMethod.median:
                    return this.get_quartile(value_list);
                case lineupjs_1.EAdvancedSortMethod.q1:
                    return this.get_quartile(value_list, 1);
                case lineupjs_1.EAdvancedSortMethod.q3:
                    return this.get_quartile(value_list, 3);
                default:
                    return this.get_quartile(value_list);
            }
        }
        toCompareValue(row) {
            let data = this.getValue(row);
            let value_list = data[0]["value"];
            const method = this.getSortMethod();
            return this.get_advanced_value(method, value_list);
        }
        toCompareValueType() {
            return lineupjs_1.ECompareValueType.FLOAT;
        }
        getBoxPlotDataFromValueList(data) {
            return {
                "mean": this.get_advanced_value(lineupjs_1.EAdvancedSortMethod.mean, data),
                "missing": 0,
                "count": data.length,
                "max": this.get_advanced_value(lineupjs_1.EAdvancedSortMethod.max, data),
                "min": this.get_advanced_value(lineupjs_1.EAdvancedSortMethod.min, data),
                "median": this.get_advanced_value(lineupjs_1.EAdvancedSortMethod.median, data),
                "q1": this.get_advanced_value(lineupjs_1.EAdvancedSortMethod.q1, data),
                "q3": this.get_advanced_value(lineupjs_1.EAdvancedSortMethod.q3, data)
            };
        }
        getBoxPlotData(row) {
            console.log("getBoxPlotData");
            const data = this.getValue(row)[0]["value"];
            if (data == null) {
                return null;
            }
            return this.getBoxPlotDataFromValueList(data);
        }
        getRawBoxPlotData(row) {
            console.log("getRawBoxPlotData");
            const data = this.getRawValue(row)[0]["value"];
            if (data == null) {
                return null;
            }
            return this.getBoxPlotDataFromValueList(data);
        }
        getRange() {
            console.log("getRange");
            return this.mapping.getRange(this.numberFormat);
        }
        getColorMapping() {
            console.log("getColorMapping");
            return this.colorMapping.clone();
        }
        getNumber(row) {
            // console.log("getNumber")
            return this.mapping.apply(this.toCompareValue(row));
        }
        getRawNumber(row) {
            // console.log("getRawNumber")
            return this.toCompareValue(row);
        }
        iterNumber(row) {
            // console.log("iterNumber")
            const r = this.getValue(row);
            // return r ? r.map((d) => d.value) : [NaN];
            // return r ? r[0]["value"] : [NaN];
            return [this.get_advanced_value(lineupjs_1.EAdvancedSortMethod.median, r[0]["value"])];
        }
        iterRawNumber(row) {
            // console.log("iterRawNumber")
            const r = this.getRawValue(row);
            // return r ? r.map((d) => d.value) : [NaN];
            // return r ? r[0]["value"] : [NaN];
            return [this.get_advanced_value(lineupjs_1.EAdvancedSortMethod.median, r[0]["value"])];
        }
        getValue(row) {
            const values = this.getRawValue(row);
            if (values.length === 0)
                return null;
            return values.map(({ key, value }) => {
                return { key, value: value.length === 0 ? null : value.map(val => this.mapping.apply(val)) };
            });
        }
        getRawValue(row) {
            const r = super.getValue(row);
            return r == null ? [] : r;
            // const values = super.getValue(row);
            // if(values.length === 0)
            //     return null;
            // return values.map(({key, value}) => {
            //     return {key, value: value.length===0 ? null : value};
            // });
        }
        getExportValue(row, format) {
            return format === 'json' ? this.getRawValue(row) : super.getExportValue(row, format);
        }
        getLabels(row) {
            const v = this.getRawValue(row);
            return Object.keys(v)
                .map((key) => ({ key, value: v[key].map(val => this.numberFormat(val)) }));
        }
        getSortMethod() {
            return this.sort;
        }
        setSortMethod(sort) {
            if (this.sort === sort) {
                return;
            }
            this.fire([TestColumn_1.EVENT_SORTMETHOD_CHANGED], this.sort, this.sort = sort);
            // sort by me if not already sorted by me
            if (!this.isSortedByMe().asc) {
                this.sortByMe();
            }
        }
        dump(toDescRef) {
            const r = super.dump(toDescRef);
            r.sortMethod = this.getSortMethod();
            r.filter = !helper_methods_1.isDummyNumberFilter(this.currentFilter) ? this.currentFilter : null;
            r.map = this.mapping.toJSON();
            return r;
        }
        restore(dump, factory) {
            super.restore(dump, factory);
            if (dump.sortMethod) {
                this.sort = dump.sortMethod;
            }
            if (dump.filter) {
                this.currentFilter = helper_methods_1.restoreNumberFilter(dump.filter);
            }
            if (dump.map || dump.domain) {
                this.mapping = helper_methods_1.restoreMapping(dump, factory);
            }
        }
        createEventList() {
            return super.createEventList().concat([TestColumn_1.EVENT_MAPPING_CHANGED, TestColumn_1.EVENT_SORTMETHOD_CHANGED, TestColumn_1.EVENT_FILTER_CHANGED]);
        }
        on(type, listener) {
            return super.on(type, listener);
        }
        getOriginalMapping() {
            return this.original.clone();
        }
        getMapping() {
            return this.mapping.clone();
        }
        setMapping(mapping) {
            if (this.mapping.eq(mapping)) {
                return;
            }
            this.fire([TestColumn_1.EVENT_MAPPING_CHANGED, lineupjs_1.Column.EVENT_DIRTY_VALUES, lineupjs_1.Column.EVENT_DIRTY], this.mapping.clone(), this.mapping = mapping);
        }
        getColor(row) {
            return lineupjs_1.NumberColumn.prototype.getColor.call(this, row);
        }
        isFiltered() {
            return lineupjs_1.NumberColumn.prototype.isFiltered.call(this);
        }
        getFilter() {
            return lineupjs_1.NumberColumn.prototype.getFilter.call(this);
        }
        setFilter(value) {
            lineupjs_1.NumberColumn.prototype.setFilter.call(this, value);
        }
        // filter(row: IDataRow) {
        //   return NumberColumn.prototype.filter.call(this, row);
        // }
        /** @internal */
        isNumberIncluded(filter, value) {
            if (!filter) {
                return true;
            }
            if (Number.isNaN(value)) {
                return !filter.filterMissing;
            }
            return !((isFinite(filter.min) && value < filter.min) || (isFinite(filter.max) && value > filter.max));
        }
        /**
         * filter the current row if any filter is set
         * @param row
         * @returns {boolean}
         */
        // TODO: customize filter: max, min, median...
        filter(row) {
            // const value = this.getRawNumber(row);
            const value = this.get_advanced_value(lineupjs_1.EAdvancedSortMethod.median, this.getRawValue(row)[0]["value"]);
            return this.isNumberIncluded(this.getFilter(), value);
        }
        clearFilter() {
            return lineupjs_1.NumberColumn.prototype.clearFilter.call(this);
        }
    };
    TestColumn.EVENT_MAPPING_CHANGED = lineupjs_1.NumberColumn.EVENT_MAPPING_CHANGED;
    TestColumn.EVENT_COLOR_MAPPING_CHANGED = lineupjs_1.NumberColumn.EVENT_COLOR_MAPPING_CHANGED;
    TestColumn.EVENT_SORTMETHOD_CHANGED = lineupjs_1.NumberColumn.EVENT_SORTMETHOD_CHANGED;
    TestColumn.EVENT_FILTER_CHANGED = lineupjs_1.NumberColumn.EVENT_FILTER_CHANGED;
    TestColumn = TestColumn_1 = __decorate([
        lineupjs_1.toolbar('rename', 'filterNumber', 'sort', 'sortBy'),
        lineupjs_1.dialogAddons('sort', 'sortNumbers'),
        lineupjs_1.SortByDefault('descending')
    ], TestColumn);
    return TestColumn;
})();
exports.TestColumn = TestColumn;
