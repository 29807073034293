"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.setProjectionParamsAction = exports.DistanceMetric = exports.EncodingMethod = exports.NormalizationMethod = void 0;
var NormalizationMethod;
(function (NormalizationMethod) {
    NormalizationMethod[NormalizationMethod["STANDARDIZE"] = 0] = "STANDARDIZE";
    NormalizationMethod[NormalizationMethod["NORMALIZE01"] = 1] = "NORMALIZE01"; // values are mapped between [0;1]
})(NormalizationMethod = exports.NormalizationMethod || (exports.NormalizationMethod = {}));
var EncodingMethod;
(function (EncodingMethod) {
    EncodingMethod[EncodingMethod["ONEHOT"] = 0] = "ONEHOT";
    EncodingMethod[EncodingMethod["NUMERIC"] = 1] = "NUMERIC";
})(EncodingMethod = exports.EncodingMethod || (exports.EncodingMethod = {}));
var DistanceMetric;
(function (DistanceMetric) {
    DistanceMetric[DistanceMetric["EUCLIDEAN"] = 0] = "EUCLIDEAN";
    DistanceMetric[DistanceMetric["JACCARD"] = 1] = "JACCARD";
    DistanceMetric[DistanceMetric["GOWER"] = 2] = "GOWER";
    DistanceMetric[DistanceMetric["COSINE"] = 3] = "COSINE";
    DistanceMetric[DistanceMetric["MANHATTAN"] = 4] = "MANHATTAN";
})(DistanceMetric = exports.DistanceMetric || (exports.DistanceMetric = {}));
const SET = "ducks/projectionParams/SET";
exports.setProjectionParamsAction = projectionParams => ({
    type: SET,
    projectionParams: projectionParams
});
const initialState = {
    perplexity: 50,
    learningRate: 50,
    nNeighbors: 15,
    iterations: 1000,
    seeded: false,
    useSelection: false,
    method: '',
    distanceMetric: DistanceMetric.EUCLIDEAN,
    normalizationMethod: NormalizationMethod.STANDARDIZE,
    encodingMethod: EncodingMethod.ONEHOT
};
const projectionParams = (state = initialState, action) => {
    switch (action.type) {
        case SET:
            return action.projectionParams;
        default:
            return state;
    }
};
exports.default = projectionParams;
