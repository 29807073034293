"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.DatasetTabPanel = void 0;
const core_1 = require("@material-ui/core");
const React = require("react");
const Vect_1 = require("../../Utility/Data/Vect");
const CSVLoader_1 = require("../../Utility/Loaders/CSVLoader");
const JSONLoader_1 = require("../../Utility/Loaders/JSONLoader");
const LoadingIndicator_1 = require("../../Utility/Loaders/LoadingIndicator");
const SDFLoader_1 = require("../../Utility/Loaders/SDFLoader");
const DatasetDrop_1 = require("./DatasetDrop");
const DownloadJob_1 = require("./DownloadJob");
const DownloadProgress_1 = require("./DownloadProgress");
const PredefinedDatasets_1 = require("./PredefinedDatasets");
const SDFModifierDialog_1 = require("./SDFModifierDialog");
const UploadedFiles_1 = require("./UploadedFiles");
const frontend_utils = require("../../../utils/frontend-connect");
const promise_helpers_1 = require("../../../utils/promise-helpers");
var d3v5 = require('d3');
function convertFromCSV(vectors) {
    return vectors.map(vector => {
        return new Vect_1.Vect(vector);
    });
}
function DatasetTabPanel({ onDataSelected }) {
    const [job, setJob] = React.useState(null);
    const [entry, setEntry] = React.useState(null);
    const [openSDFDialog, setOpen] = React.useState(false);
    const [refreshUploadedFiles, setRefreshUploadedFiles] = React.useState(0);
    const { cancellablePromise, cancelPromises } = promise_helpers_1.default();
    let abort_controller = new AbortController();
    function onModifierDialogClose(modifiers) {
        setOpen(false);
        if (modifiers !== null) {
            abort_controller = new AbortController();
            new SDFLoader_1.SDFLoader().resolvePath(entry, onDataSelected, cancellablePromise, modifiers, abort_controller);
        }
    }
    let predefined = null;
    if (frontend_utils.CHEM_PROJECT) {
        predefined = React.createElement(UploadedFiles_1.UploadedFiles, { onChange: (entry) => {
                setEntry(entry);
                setOpen(true);
            }, refresh: refreshUploadedFiles });
    }
    else {
        predefined = React.createElement(PredefinedDatasets_1.PredefinedDatasets, { onChange: (entry) => {
                if (entry.path.endsWith('sdf')) {
                    setEntry(entry);
                    setOpen(true);
                }
                else {
                    setJob(new DownloadJob_1.DownloadJob(entry));
                }
            } });
    }
    return React.createElement("div", { style: { display: 'flex', flexDirection: 'column', height: '100%' } },
        !(frontend_utils.DEMO && frontend_utils.CHEM_PROJECT) && React.createElement(core_1.Box, { paddingLeft: 2, paddingTop: 2 },
            React.createElement(core_1.Typography, { variant: "subtitle2", gutterBottom: true }, 'Custom Datasets (Drag and Drop)')),
        !(frontend_utils.DEMO && frontend_utils.CHEM_PROJECT) &&
            React.createElement(DatasetDrop_1.DatasetDrop, { onChange: (var1, var2) => {
                    onDataSelected(var1, var2);
                    setRefreshUploadedFiles(refreshUploadedFiles + 1);
                }, cancellablePromise: cancellablePromise, abort_controller: abort_controller }),
        !(frontend_utils.DEMO && frontend_utils.CHEM_PROJECT) &&
            React.createElement(core_1.Box, { paddingLeft: 2, paddingTop: 2 },
                React.createElement(core_1.Typography, { variant: "subtitle2", gutterBottom: true }, 'Predefined Datasets')),
        predefined,
        React.createElement(DownloadProgress_1.DownloadProgress, { job: job, onFinish: (result) => {
                if (job.entry.path.endsWith('json')) {
                    new JSONLoader_1.JSONLoader().resolve(JSON.parse(result), onDataSelected, job.entry.type, job.entry);
                }
                else {
                    new CSVLoader_1.CSVLoader().resolve(onDataSelected, convertFromCSV(d3v5.csvParse(result)), job.entry.type, job.entry);
                }
                setJob(null);
            }, onCancel: () => { setJob(null); } }),
        React.createElement(LoadingIndicator_1.LoadingIndicatorDialog, { handleClose: () => { cancelPromises(); }, area: "global_loading_indicator" }),
        React.createElement(SDFModifierDialog_1.SDFModifierDialog, { openSDFDialog: openSDFDialog, handleClose: onModifierDialogClose }));
}
exports.DatasetTabPanel = DatasetTabPanel;
