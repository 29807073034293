"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.PredefinedDatasets = void 0;
const core_1 = require("@material-ui/core");
const React = require("react");
const TypeIcon_1 = require("../../Icons/TypeIcon");
const DatasetType_1 = require("../../Utility/Data/DatasetType");
const DatasetDatabase_1 = require("../../Utility/Data/DatasetDatabase");
exports.PredefinedDatasets = ({ onChange }) => {
    var database = new DatasetDatabase_1.DatasetDatabase();
    var types = database.getTypes();
    var handleClick = (entry) => {
        onChange(entry);
        //if (entry.path.endsWith('json')) {
        //    new JSONLoader().resolvePath(entry, onChange)
        //} else {
        //    new CSVLoader().resolvePath(entry, onChange)
        //}
    };
    return React.createElement(core_1.Grid, { item: true, style: { overflowY: 'auto', height: '100px', flex: '1 1 auto' } },
        React.createElement(core_1.List, { subheader: React.createElement("li", null), style: { backgroundColor: 'white' } }, types.map(type => {
            return React.createElement("li", { key: type, style: { backgroundColor: 'inherit' } },
                React.createElement("ul", { style: { backgroundColor: 'inherit', paddingInlineStart: '0px' } },
                    React.createElement(core_1.ListSubheader, null, Object.keys(DatasetType_1.DatasetType)[Object.values(DatasetType_1.DatasetType).indexOf(type)].replaceAll('_', ' ')),
                    database.data.filter(value => value.type == type).map(entry => {
                        return React.createElement(core_1.ListItem, { key: entry.path, button: true, onClick: () => {
                                handleClick(entry);
                            } },
                            React.createElement(TypeIcon_1.TypeIcon, { type: entry.type }),
                            React.createElement(core_1.ListItemText, { primary: entry.display }));
                    })));
        })));
};
